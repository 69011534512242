.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.thumbNailVideoContainer {
  width: 100%;
  height: 203px;
}

.thumbNailVideoContainer iframe {
  width: 100%;
  height: 100%;
}

.videoContainer {
  display: flex;
  background-color: #fff;
}

.videoContainer #articleVideoIframe {
  width: 100%!important;
  height: 420px!important;
}

#wmfdSportsImg {
  width: 96px;
  margin-right: 8px;
}
#schoolCluster {
  width: 330px;
  height: 40px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.cp-post-tools .li2 {
  color: blue;

}

#sportsDesktopBanner {
  display: none;
}
#sportsMobileBanner {
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .videoContainer #articleVideoIframe {
    height: 405px!important;
  }
  .thumbNailVideoContainer {
    height: 165px;
  }
}


@media screen and (max-width: 992px) {
  .thumbNailVideoContainer {
    height: 194px;
  }
}

@media screen and (min-width: 900px)  {
  #sportsMobileBanner {
    display: none;
  }
  #sportsDesktopBanner {
    display: block;
    max-width: 780px;
    margin: auto!important;
    padding: 11px;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 775px) {
  .videoContainer #articleVideoIframe {
    height: 413px!important;
  }
}

@media screen and (max-width: 767px) {
  .thumbNailVideoContainer {
    height: 400px;
  }
}

@media screen and (max-width: 650px) {
  .videoContainer #articleVideoIframe {
    height: 349px!important;
  }
  .thumbNailVideoContainer {
    height: 349px;
  }
}

@media screen and (max-width: 550px) {
  .videoContainer #articleVideoIframe {
    height: 293px!important;
  }
  .thumbNailVideoContainer {
    height: 291px;
  }
}

@media screen and (max-width: 450px) {
  .videoContainer #articleVideoIframe {
    height: 236px!important;
  }
  .thumbNailVideoContainer {
    height: 235px;
  }
  #wmfdSportsImg {
    width: 85px;
  }
  #schoolCluster {
    width: 280px;
    height: 33px;
  }
}

@media screen and (max-width: 400px) {
  #wmfdSportsImg {
    width: 70px;
  }
  #schoolCluster {
    width: 250px;
    height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .videoContainer #articleVideoIframe {
    height: 180px!important;
  }
  .thumbNailVideoContainer {
    height: 180px;
  }
}
